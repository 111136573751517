import {useDispatch, useSelector} from "react-redux";
import {sessionsActions, sessionsSelectors} from "@features/sessions";
import {useCallback} from "react";

export const useSessionsFilter = () => {
  const dispatch = useDispatch();
  const sessionsFilter = useSelector(sessionsSelectors.selectListFilter);

  const setSessionsFilter = useCallback((params) => {
    dispatch(sessionsActions.updateFilteredList(params));
  }, []);

  return [sessionsFilter, setSessionsFilter];
};
