import React from "react";
import "react-quill/dist/quill.snow.css";
import "@styles/quill-editor.less";

export const TextDisplayer = ({value}) => (
  <div className="ql-snow">
    <div className="ql-editor ql-editor-readonly" dangerouslySetInnerHTML={{__html: value}} />
  </div>
);

export default TextDisplayer;
