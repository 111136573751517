/**
 * All credits go to `antd-form-builder` for creating this nice framework,
 * I have only modified it a little to suit my needs. Thank you !
 * https://www.npmjs.com/package/antd-form-builder
 */

import FormRenderer from "./FormRenderer";
import defineWidgets from "./defineWidgets";

defineWidgets();
export default FormRenderer;
