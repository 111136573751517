import {ReactComponentElement, useState} from "react";
import FormRendererField from "./FormRendererField";
import {shouldShowField} from "./shouldShowField";
import type {FieldType} from "./index";

const WIDGET_DEFINITIONS = {};

function getWidget(widget) {
  if (!widget) return null;

  if (typeof widget === "string") {
    if (!WIDGET_DEFINITIONS[widget]?.widget) {
      throw new Error(
        `Widget '${widget}' not found, did you defined it by FormRenderer.defineComponent?`
      );
    }
    return WIDGET_DEFINITIONS[widget].widget;
  } else return widget; // Else, just render the react component as it is
}

// For each field of the form, gather :
//  - The field props given
//  - Pass them into the metaConvertor of the corresponding widget, if there is one
//  - Gather the new metaConverted field props, the widget component, and the type and return it.
function prepareFields(fields) {
  return fields.map((field) => {
    const widget = getWidget(field.widget);

    // Find the widget and the meta convertor
    const widgetDefForField = Object.values(WIDGET_DEFINITIONS).find(
      (widgetDef) => widgetDef.widget === widget && widgetDef.metaConvertor
    );

    if (widgetDefForField?.metaConvertor) {
      // If we find a metaConvertor, then we use it to transform data
      const newField = widgetDefForField.metaConvertor(field) || field;
      if (!newField) {
        throw new Error(`metaConvertor of '${String(field.widget)}' must return a field`);
      }
      return {...newField, widget, type: newField.widget};
    } else {
      // If no widget def or no metaConvertor found, then return the field as it is
      return {...field, widget, type: field.widget};
    }
  });
}

function FormRenderer({fields = [], disabled = false, form, minimalist = false}) {
  fields = fields.map((formComp) => ({
    ...formComp,
    widget: formComp.type, // Copy the type to the widget prop (legacy, should be cleaned)
  }));

  const preparedFields = prepareFields(fields);

  return preparedFields
    .filter((field) =>
      shouldShowField(
        field.conditional,
        preparedFields,
        form.getFieldValue(field.conditional?.when)
      )
    )
    .map((field, index) => (
      <FormRendererField
        key={field.key || index}
        field={field}
        disabled={disabled}
        minimalist={minimalist}
        form={form}
      />
    ));
}

// define a new widget to display input information
FormRenderer.defineWidget = (
  name: string,
  Widget: ReactComponentElement,
  metaConvertor: (field: FieldType) => FieldType = null
) => {
  if (WIDGET_DEFINITIONS[name]) throw new Error(`Widget "${name}" already defined.`);

  // Instanciate the widget so it doesn't get duplicated between several instanciations
  // Some weird stuff going on otherwise
  WIDGET_DEFINITIONS[name] = {
    widget: (props) => <Widget {...props} />,
    metaConvertor,
  };
};

// Force a whole re-render of the form
FormRenderer.useForceUpdate = () => {
  const [, updateState] = useState();
  return () => updateState({});
};

export default FormRenderer;
