import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {sorter} from "@shared/utils/sorters";
import {EntitiesSelectors} from "@utils/features/types";

const activitiesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: activitiesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: activitiesAdapter.reducers,
});

const asyncActions = {
  loadList:
    ({forceLoad} = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;
      const connected = state.currentUser.connected;

      await loadListFromBackend(
        `activities${connected ? "" : "/public"}`,
        projectId,
        state.activities.init,
        () => dispatch(activitiesActions.initContext(projectId)),
        (data) => dispatch(activitiesActions.initList({list: data, project: projectId})),
        forceLoad
      );
    },
};

const activitiesAdapterSelectors = activitiesAdapter.getSelectors((state) => state.activities);

export const activitiesSelectors: EntitiesSelectors<any, any> = {
  selectList: activitiesAdapterSelectors.selectAll,
  selectById: activitiesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.activities.init.status === "loaded",
};

export const activitiesReducer = activitiesSlice.reducer;

export const activitiesActions = {
  ...activitiesSlice.actions,
  ...asyncActions,
};
