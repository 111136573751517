import {createSlice} from "@reduxjs/toolkit";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {sorter} from "@shared/utils/sorters";
import {EntitiesSelectors} from "@utils/features/types";

const teamsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState: teamsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: teamsAdapter.reducers,
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const connected = state.currentUser.connected;

    await loadListFromBackend(
      `teams/${connected ? "" : "public"}`,
      projectId,
      state.teams.init,
      () => dispatch(teamsActions.initContext(projectId)),
      (data) => dispatch(teamsActions.initList({list: data, project: projectId}))
    );
  },
};

const teamsAdapterSelectors = teamsAdapter.getSelectors((state) => state.teams);

export const teamsSelectors: EntitiesSelectors<any, any> = {
  selectList: teamsAdapterSelectors.selectAll,
  selectById: teamsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.teams.init.status === "loaded",
};

export const teamsReducer = teamsSlice.reducer;

export const teamsActions = {
  ...teamsSlice.actions,
  ...asyncActions,
};
