import {currentProjectSelectors} from "@features/currentProject";
import {useSelector} from "react-redux";
import {FieldCompInput, FieldInputComps} from "../components/FieldsBuilder/FieldComp/FieldComp";
import {NOEEndpointExtended} from "../inputs/NOEObjectSelectInput";

export type CustomFieldDisplayMode =
  | "inEditPage"
  | "inListView"
  | "inOrgaFilters"
  | "inParticipantFilters"
  | "inSessionsCards"
  | "inParticipantSessionView";

export const allowedSelectOneFields = ["radioGroup", "select"];
export const allowedSelectMultipleFields = ["checkboxGroup", "multiSelect"];
export const allowedTypesInFilters = [...allowedSelectOneFields, ...allowedSelectMultipleFields];

const shouldBeDisplayedFunctions: Record<
  CustomFieldDisplayMode,
  (fieldComp: FieldCompInput) => boolean
> = {
  inEditPage: () => true,
  inListView: (fieldComp) =>
    !!fieldComp.displayName && !!fieldComp.displayOptions?.includes("inListView"),
  inOrgaFilters: (fieldComp) =>
    !!fieldComp.displayName &&
    !!fieldComp.displayOptions?.includes("inOrgaFilters") &&
    allowedTypesInFilters.includes(fieldComp.type),
  inParticipantFilters: (fieldComp) =>
    !!fieldComp.displayName &&
    !!fieldComp.displayOptions?.includes("inParticipantFilters") &&
    allowedTypesInFilters.includes(fieldComp.type),
  inSessionsCards: (fieldComp) =>
    !!fieldComp.displayName && !!fieldComp.displayOptions?.includes("inSessionsCards"),
  inParticipantSessionView: (fieldComp) =>
    !!fieldComp.displayName && !!fieldComp.displayOptions?.includes("inParticipantSessionView"),
};

export const useGetCustomFieldsForEndpointAndMode = (options: {
  mode: CustomFieldDisplayMode;
  endpoint?: NOEEndpointExtended;
}) => {
  const customFieldsComponents: FieldInputComps =
    useSelector((s) => currentProjectSelectors.selectProject(s).customFieldsComponents) || [];

  // Tell if the comp should be displayed in this current mode
  const shouldBeDisplayedFn = shouldBeDisplayedFunctions[options.mode];

  // Tell if the comp matches the given endpoint. If no endpoint, matches everything.
  const isForGivenEndpoint = (fieldComp: FieldCompInput) =>
    options.endpoint ? fieldComp.meta.endpoint === options.endpoint : true;

  return customFieldsComponents.filter(
    (fieldComp) => isForGivenEndpoint(fieldComp) && shouldBeDisplayedFn(fieldComp)
  );
};
