import {t} from "i18next";

export const phoneValidator = async (rule, value) => {
  // No phone is allowed, use a "required" rule if you want to make it mandatory
  if (!value || value === "") return Promise.resolve();

  if (value[0] !== "+") {
    return Promise.reject(t("common:inputElement.error.preferInternationalPhoneNumber"));
  }

  const {isValidPhoneNumber} = await import(/* webpackPrefetch: true */ "libphonenumber-js");
  try {
    if (isValidPhoneNumber(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("common:inputElement.error.enterAValidPhoneNumber"));
    }
  } catch (e) {
    return Promise.reject(t("common:inputElement.error.enterAValidPhoneNumber"));
  }
};
