import {categoriesActions} from "@features/categories";
import {currentProjectSelectors} from "@features/currentProject";
import {sessionsActions, sessionsSelectors} from "@features/sessions";
import {teamsActions} from "@features/teams";
import dayjs from "@shared/services/dayjs";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {placesActions} from "../../../features/places"; // Added import for placesActions

export type ViewUrl = "subscribed" | "all";
export type ViewMode = "agenda" | "list";

export const useLoadSessionsView = (viewUrl: ViewUrl, viewMode: ViewMode) => {
  const dispatch = useDispatch();
  const sessionFilter = useSelector(sessionsSelectors.selectListFilter);
  const currentProject = useSelector(currentProjectSelectors.selectProject);

  useEffect(() => {
    const isAgendaView = viewMode === "agenda";
    const eventIsFinished = dayjs().isAfter(dayjs(currentProject.end));

    // If we are on the agenda view, or if the past sessions filter is activated, or if the event is already finished,
    // Then load all sessions (= no dateToLoadFrom). Else, only load from now date.
    const dateToLoadFrom =
      isAgendaView || sessionFilter.showPastSessionsFilter || eventIsFinished
        ? undefined
        : Date.now();

    dispatch(sessionsActions.loadList({type: viewUrl, fromDate: dateToLoadFrom}));

    // In the agenda view, always activate past sessions.
    // If we are after the end of the event, also systematically load past sessions.
    // TODO optimize this, as updateFilteredList is also called in the loadList function
    if (isAgendaView || eventIsFinished)
      dispatch(sessionsActions.updateFilteredList({showPastSessionsFilter: true}));

    dispatch(categoriesActions.loadList());
    dispatch(teamsActions.loadList());
    currentProject.usePlaces && dispatch(placesActions.loadList());
  }, [viewUrl, sessionFilter.showPastSessionsFilter]);
};
