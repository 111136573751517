/**
 * Changes the URL params in the URL without causing refresh at all
 */
export const setNewSearchParamsWithoutRefresh = (newSearchParams: URLSearchParams) => {
  const newSearchParamsString = newSearchParams.toString();
  const finalSearchParams = newSearchParamsString?.length > 0 ? `?${newSearchParamsString}` : "";

  const urlWithNewSearchParams = window.location.href.split("?")[0] + finalSearchParams;
  window.history.replaceState({path: urlWithNewSearchParams}, "", urlWithNewSearchParams); // Replace in the URL without triggering a refresh
};
