import {createSlice} from "@reduxjs/toolkit";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {sorter} from "@shared/utils/sorters";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {EntitiesSelectors} from "@utils/features/types";

const categoriesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: categoriesAdapter.reducers,
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const connected = state.currentUser.connected;

    await loadListFromBackend(
      `categories/${connected ? "" : "public"}`,
      projectId,
      state.categories.init,
      () => dispatch(categoriesActions.initContext(projectId)),
      (data) => dispatch(categoriesActions.initList({list: data, project: projectId})),
      false
    );
  },
};

const categoriesAdapterSelectors = categoriesAdapter.getSelectors((state) => state.categories);

export const categoriesSelectors: EntitiesSelectors<any, any> = {
  selectList: categoriesAdapterSelectors.selectAll,
  selectById: categoriesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.categories.init.status === "loaded",
};

export const categoriesReducer = categoriesSlice.reducer;

export const categoriesActions = {
  ...categoriesSlice.actions,
  ...asyncActions,
};
