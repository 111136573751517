import {Radio, RadioGroupProps as AntdRadioGroupProps} from "antd";
import React, {ReactNode} from "react";
import {FormItem, FormItemProps} from "./FormItem";

type RadioGroupProps = Omit<AntdRadioGroupProps, "options"> & {
  options?: Array<{value: string | number; key: React.Key; label: ReactNode}>;
};

export const RadioGroupComponent = ({options, ...props}: RadioGroupProps) => {
  return (
    <Radio.Group {...props}>
      <div className={"containerV"}>
        {options?.map((opt) => (
          <Radio value={opt.value} key={opt.value}>
            {opt.label}
          </Radio>
        ))}
      </div>
    </Radio.Group>
  );
};

export const RadioGroupInput = (props: FormItemProps<RadioGroupProps>) => (
  <FormItem {...props}>
    <RadioGroupComponent />
  </FormItem>
);
