import {createSelector, createSlice} from "@reduxjs/toolkit";
import dayjs from "@shared/services/dayjs";
import {
  displayLoadingMessage,
  fetchWithMessages,
  killLoadingMessage,
} from "@shared/utils/api/fetchWithMessages";
import {getVolunteeringCoefficient} from "@shared/utils/sessionsUtilities";
import {registrationsActions} from "./registrations";
import {ACTIVATING_OFFLINE_MODE, OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {t} from "i18next";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {searchInObjectsList} from "@shared/utils/searchInObjectsList";
import {getSessionMetadata} from "@shared/utils/getSessionMetadata";
import {filterSessionsByCustomFields} from "@routes/sessions/atoms/filterSessionsByCustomFields";
import {ViewUrl} from "@routes/sessions/atoms/useLoadSessionsView";
import {setNewSearchParamsWithoutRefresh} from "../utils/setNewSearchParamsWithoutRefresh";
import {fromFormValuesToFilters} from "../routes/sessions/atoms/CustomFieldsFilters";
import {initialSearchParams} from "./view";
import {placesSelectors} from "./places";

export const specialCategoriesFilterOptions = ["volunteering", "allTheRest"];

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState: {
    init: undefined,
    list: [],
    listFiltered: [],
    editing: {},
    filter: {
      searchBarFilter: "",
      registeredFilter: undefined,
      availableFilter: false,
      showPastSessionsFilter: false,
      stewardFilter: false,
      fromDateFilter: undefined,
      isFavoriteFilter: false,
      categoriesFilter: initialSearchParams.sessions?.categories || [],
      customFieldsFilters: initialSearchParams.sessions?.customFields,
    },
    slotList: [],
    slotListFiltered: [],
  },
  reducers: {
    updateInList: (state, action) => {
      state.list[state.list.findIndex((session) => session._id === action.payload._id)] =
        action.payload;
    },
    initList: (state, action) => {
      state.init = action.payload.project;
      state.list = action.payload.list || [];
      state.slotList = state.list
        .map((session) => session.slots.map((slot) => ({...slot, session})))
        .flat();
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };

      // Save the agenda params in the URL
      const newSearchParams = new URLSearchParams(window.location.search);
      const filtersForSearchParams = {
        categories: state.filter.categoriesFilter,
        ...state.filter.customFieldsFilters,
      };
      // Synchronise the filters with the search params: if a filter is empty, remove it from the search params
      Object.entries(filtersForSearchParams).forEach(([key, value]) => {
        if (value?.length > 0) newSearchParams.set(key, value);
        else newSearchParams.delete(key);
      });
      setNewSearchParamsWithoutRefresh(newSearchParams);
    },
    changeListFiltered: (state, action) => {
      state.listFiltered = action.payload;
      state.slotListFiltered = state.listFiltered
        .map((session) => session.slots.map((slot) => ({...slot, session: session})))
        .flat();
    },
    resetState: (state) => {
      state.init = false;
      state.initCategories = false;
      state.list = [];
      state.listFiltered = [];
      state.slotList = undefined;
      state.slotListFiltered = [];
      state.categories = [];
    },
  },
});

const multiStateActions = {
  updateFilteredList: (payload) => (dispatch, getState) => {
    const state = getState();

    // update the filter
    const filter = {...state.sessions.filter, ...payload};
    dispatch(sessionsActions.changeFilter(payload));

    // Apply filter & update the data
    const registeredFilter = filter.registeredFilter;
    const availableFilter = filter.availableFilter;
    const showPastSessionsFilter = filter.showPastSessionsFilter;
    const stewardFilter = filter.stewardFilter;
    const fromDateFilter = new Date(filter.fromDateFilter);

    // Filter with searchbar
    let filteredSessions = searchInObjectsList(
      filter.searchBarFilter,
      state.sessions.list,
      (session) => [
        session.name,
        session.activity.name,
        session.activity.category.name,
        ...(session.activity.secondaryCategories || []),
      ]
    );

    // Filter with all the rest
    filteredSessions = filteredSessions
      .filter((session) => {
        // This code is shared across frontends, cf. orga-front/src/helpers/agendaUtilities.js filterAppointments()
        let categories;
        if (filter.categoriesFilter?.length > 0) {
          // Filter by "special categories"
          let specialCategoriesFilter;
          const filterVolunteering = filter.categoriesFilter.includes("volunteering");
          const filterAllTheRest = filter.categoriesFilter.includes("allTheRest");
          if (filterAllTheRest && !filterVolunteering) {
            specialCategoriesFilter = getVolunteeringCoefficient(session) === 0;
          } else if (filterVolunteering && !filterAllTheRest) {
            specialCategoriesFilter = getVolunteeringCoefficient(session) > 0;
          } else if (filterVolunteering && filterAllTheRest) {
            specialCategoriesFilter = true; // if we have both volunteering and allTheRest, it's like we don't filter at all
          } else {
            specialCategoriesFilter = false; // if we have none, then we don't wanna filter with it at all
          }

          // Then filter only by category so we need to remove the special categories entries from the rest
          const pureCategoriesFilter = filter.categoriesFilter.filter(
            (category) => !specialCategoriesFilterOptions.includes(category)
          );

          categories =
            specialCategoriesFilter ||
            (pureCategoriesFilter.length > 0
              ? pureCategoriesFilter.includes(session.activity.category._id)
              : false); // Don't filter if there are no pure categories selected
        } else {
          categories = true; // Don't filter if deactivated
        }

        const subscribed =
          registeredFilter === undefined || registeredFilter === null // Not set, or set to "All"
            ? true // Don't filter if deactivated
            : session.subscribed === registeredFilter || session.isSteward; // Either it is, and we display the ones for the selection + sessions where we are steward

        const steward = stewardFilter ? session.isSteward === stewardFilter : true; // Don't filter if deactivated

        const date = filter.fromDateFilter
          ? dayjs(fromDateFilter).startOf("day").isBefore(dayjs(session.start))
          : true; // Don't filter if deactivated

        const showPastSessions = showPastSessionsFilter
          ? true
          : dayjs(session.end).isAfter(dayjs()); // Filter sessions that are only after now

        const isFavorite = filter.isFavoriteFilter
          ? state.view.favorites[state.currentProject.project._id]?.includes(session._id)
          : true;

        let available;
        if (availableFilter) {
          const currentProject = state.currentProject.project;
          const currentRegistration = state.registrations.current;

          const {
            isSteward,
            sessionIsFull,
            participantIsNotAvailable,
            alreadyStewardOnOtherSession,
            alreadySubscribedToOtherSession,
            registrationIncomplete,
          } = getSessionMetadata(session, currentRegistration);

          const subscriptionIsDisabled =
            sessionIsFull ||
            registrationIncomplete ||
            ((alreadySubscribedToOtherSession || alreadyStewardOnOtherSession) &&
              currentProject.notAllowOverlap) ||
            participantIsNotAvailable ||
            isSteward;

          available = !subscriptionIsDisabled;
        } else {
          available = true;
        }

        return (
          categories && subscribed && isFavorite && showPastSessions && steward && date && available
        );
      })
      .sort((a, b) => (dayjs(a.start).isBefore(b.start) ? -1 : 1));

    if (state.currentProject.project.customFieldsComponents) {
      const augmentedCustomFields = fromFormValuesToFilters(
        filter.customFieldsFilters,
        state.currentProject.project.customFieldsComponents
      );

      filteredSessions = filterSessionsByCustomFields(filteredSessions, augmentedCustomFields);
    }

    dispatch(sessionsActions.changeListFiltered(filteredSessions));
  },
};

const asyncActions = {
  loadList:
    ({
      type,
      fromDate,
      silent,
    }: {
      type?: ViewUrl, // No type means we get everything
      fromDate?: Date | number,
      silent?: boolean,
    } = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;
      const connected = state.currentUser.connected;

      if (state.sessions.init !== projectId) {
        dispatch(sessionsActions.initList({list: [], project: projectId}));

        !silent && displayLoadingMessage();
        const data = await fetchWithMessages(
          `projects/${projectId}/sessions/selectiveLoad/${connected ? "" : "public"}`,
          {
            method: "POST",
            queryParams: {
              loadParticipantsData: OFFLINE_MODE,
              ...(ACTIVATING_OFFLINE_MODE ? {} : {type, fromDate}), // If activation of offline mode is on, just get everything directly
            },
            body: {
              alreadyLoaded: state.sessions.list.map((s) => s._id),
            },
          }
        );

        !silent && killLoadingMessage();
        if (!data) return; // If fetch fails, don't go further

        const newSessionsList = [...data.list, ...state.sessions.list];
        dispatch(sessionsActions.initList({list: newSessionsList, project: projectId}));
        dispatch(multiStateActions.updateFilteredList());

        // If not all sessions are loaded, then load the rest automatically
        if (!data.allLoaded) {
          const dataRest = await fetchWithMessages(
            `projects/${projectId}/sessions/selectiveLoad/${connected ? "" : "public"}`,
            {
              method: "POST",
              queryParams: {
                loadParticipantsData: OFFLINE_MODE,
              },
              body: {
                alreadyLoaded: newSessionsList.map((s) => s._id),
              },
            }
          );

          if (dataRest?.list.length > 0) {
            const allSessions = [...dataRest.list, ...newSessionsList];
            dispatch(sessionsActions.initList({list: allSessions, project: projectId}));
            dispatch(multiStateActions.updateFilteredList());
          }
        }
      }
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const connected = state.currentUser.connected;

    return loadEntityFromBackend(
      `sessions/${connected ? "" : "public"}`,
      entityId,
      projectId,
      state.sessions.editing,
      null, // We will never create new sessions from the end user front
      (data) => dispatch(sessionsActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE &&
          dispatch(sessionsActions.setEditing(state.sessions.list.find((s) => s._id === entityId))),
      }
    );
  },
  subscribe: (payload) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const sessionId = payload === undefined ? state.sessions.editing._id : payload;
    const sessionEndpoint = `projects/${projectId}/sessions/${sessionId}`;

    try {
      // Apply subscribing
      const {registration, updatedSession} = await fetchWithMessages(
        `${sessionEndpoint}/subscribe`,
        {
          method: "GET",
        },
        {
          200: t("sessions:messages.subscriptionSuccessful"),
          405: {
            type: "error",
            message: t("sessions:messages.noMoreSpaceAvailable"),
          },
        },
        undefined,
        true
      );

      // Always update the session even with an error, so we have the last version of it if it failed
      dispatch(sessionsActions.updateInList(updatedSession));

      // Make all the final update at the very end
      dispatch(multiStateActions.updateFilteredList());
      if (registration) dispatch(registrationsActions.setCurrentWithMetadata(registration));

      // Only if no payload was given, change the editing state
      if (!payload) dispatch(sessionsActions.setEditing(updatedSession));
    } catch (e) {
      // Do nothing
    }
  },
  unsubscribe: (payload) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;
    const sessionId = payload === undefined ? state.sessions.editing._id : payload;
    const sessionEndpoint = `projects/${projectId}/sessions/${sessionId}`;

    // Apply subscribing
    const {registration, updatedSession} = await fetchWithMessages(
      `${sessionEndpoint}/unsubscribe`,
      {method: "GET"},
      {
        200: t("sessions:messages.unsubscribeSuccessful"),
        401: t("sessions:messages.cantUnsubscribe"),
      }
    );

    dispatch(sessionsActions.updateInList(updatedSession));

    dispatch(multiStateActions.updateFilteredList());
    dispatch(registrationsActions.setCurrentWithMetadata(registration));

    // Only if no payload was given, change the editing state
    if (!payload) dispatch(sessionsActions.setEditing(updatedSession));
  },
};

export const sessionsSelectors = {
  selectList: (state) => state.sessions.list,
  selectListFiltered: (state) => state.sessions.listFiltered,
  selectEditing: (state) => state.sessions.editing,
  selectListFilter: (state) => state.sessions.filter,

  selectSlotsListForAgenda: (state) => state.sessions.slotList,
  selectSlotsListForAgendaFiltered: (state) => state.sessions.slotListFiltered,

  selectAgendaResources: createSelector([placesSelectors.selectList], (places) => {
    return [
      {
        fieldName: "placeId",
        resourceName: "places",
        title: "Espaces",
        instances: places.map((place) => ({
          text: place.name,
          id: place._id,
          availabilitySlots: place.availabilitySlots,
          displayOrder: place.displayOrder,
          color: "transparent",
        })),
        allowMultiple: true,
        hasAvailabilities: true,
      },
    ].filter((el) => !!el);
  }),
};

export const sessionsReducer = sessionsSlice.reducer;

export const sessionsActions = {
  ...sessionsSlice.actions,
  ...multiStateActions,
  ...asyncActions,
};
